.sd-question__required-text {
    display: none;
}

.sd-row__question {
    border-left: 4px solid #f56565;
  }

.sd-page__row .sd-row__panel {
      border-left: 4px solid #f56565;
}

.sd-paneldynamic .sd-row__panel {
    border-left: 0px;
}

.sd-element--nested {
    border-left: 0px;
}
  
.breaking-text {
    display: block;
    word-wrap: break-word;
    word-break: break-all;
    white-space: break-spaces; /* pre-wrap */
}