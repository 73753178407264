.hidden {
    visibility: hidden;
    opacity: 0.5;
    transition: visibility 0s 1s, opacity 1s linear;
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
}

.third-section-root h5 > span {
    font-size: 32px;
    color: white;
    line-height: 48px;
}

@media only screen and (max-device-width: 640px) {
    .third-section-root h5>span {
            font-size: 32px;
            color: white;
            font-variant: small-caps;
            line-height: 60px;
        }
}