.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-in-right {
    -webkit-animation: scale-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.scale-out-left {
    -webkit-animation: scale-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-8 14:37:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-8 14:58:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-8 15:17:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}

@keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-8 15:19:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-right {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        opacity: 1;
    }
}

@keyframes scale-in-right {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        opacity: 1;
    }
}